import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, Button, IconButton, TextField, Avatar, 
  CircularProgress, ListItemText, Typography, Grid,
  ListItem, List 
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { userApi, tipApi } from '../../services/api';
import { formatNumber } from '../../utils/Formatters';
import FoxiSearch from '../../assets/images/characters/foxi-search.png';
import FoxiCharacter from '../../assets/images/characters/foxi-half-body.png';

// Style components
const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '90vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  borderTop: '5px solid orange',
  borderTopLeftRadius: theme.spacing(7),
  borderTopRightRadius: theme.spacing(7), 
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'all 0.85s cubic-bezier(0.4, 0, 0.2, 1)',
  willChange: 'transform',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: 'white',
  zIndex: 999
}));

const PopupImage = styled('img')({
  width: '140px',
  height: '140px',
  position: 'absolute',
  top: 'calc(-8vh - 1px)',
  left: '50%',
  transform: 'translateX(-50%)',
});

const SearchingImage = styled('img')({
  position: 'absolute',
  left: '40%',
  top: '40vh',
  width: '100px',
  height: '105px',
  transform: 'translateX(-50%)',
  filter: 'brightness(0) invert(1)',
});

const fadeIn = keyframes`
  0% { opacity: 0.5; transform: scale(1); }
  50% { opacity: 0.75; transform: scale(1.15); }
  100% { opacity: 1; transform: scale(1); }
`;

const StyledList = styled(List)(({ theme }) => ({
  height: '55vh',
  overflow: 'auto',
  width: '100%',
  padding: 0,
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  width: '100%',
  height: selected ? '65px' : '60px',
  borderRadius: '15px',
  backgroundColor: selected ? 'rgba(0, 10, 19, 0.7)' : 'rgba(0, 10, 19, 0.5)',
  margin: '8px auto',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0, 10, 19, 0.7)',
    transform: 'scale(1.02)',
  }
}));

const StyledRadio = styled(Box)({
  position: 'absolute',
  right: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease',
  '& svg': {
    fontSize: 24,
    transition: 'all 0.3s ease',
  }
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    height: '55px',
    border: '3px solid white',
    backgroundColor: '#8b50e1',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
    fontFamily: 'Sora',
  },
}));

const StyledButton = styled(Button)(({ theme, isCancellButton }) => ({
  position: 'absolute',
  left: '48vw',
  transform: 'translateX(-50%)',
  width: '80%',
  height: '7vh',
  textTransform: 'none',
  fontFamily: 'Sora',
  fontSize: '17px',
  borderRadius: '10rem',
  color: 'white',
  margin: theme.spacing(0, 1),
  padding: '10px 12px',
  backgroundColor: isCancellButton ? '#d9002b' : '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: isCancellButton ? 
    `inset 0 -2px 4px 1px rgb(236 41 41 / 71%), inset 0 -4px 4px 1px #ffffff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #ee2283eb` : 
    `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5529ec`,
  textShadow: '0 1px 1px #3829ec',
  cursor: 'pointer',
  transition: 'all 0.2s',
  '&:hover': {
    width: '85%',
  },
  '&:active': {
    borderColor: '#2e23d6',
    width: '85%',
    backgroundColor: '#2e23d6',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:focus': {
    outline: 'none',
  }
}));

// User list item component
const UserListItem = React.memo(({ user, isSelected, onSelect }) => (
  <StyledListItem 
    onClick={() => onSelect(user)} 
    selected={isSelected}
  >
    <Avatar
      alt={user.username || user.firstName}
      src={`${process.env.REACT_APP_AVATAR_URL}/${user.userId}`}
      sx={{ width: 45, height: 45, mr: 2.5 }}
    />
    <Box>
      <ListItemText 
        primary={user.username || 'No username'}
        secondary={user.firstName}
        primaryTypographyProps={{ 
          fontFamily: 'sora', 
          fontWeight: 'bold', 
          fontSize: '18px' 
        }}
        secondaryTypographyProps={{ 
          fontFamily: 'sora', 
          fontWeight: '300', 
          fontSize: '15px', 
          color: 'white' 
        }}
      />
    </Box>
    <StyledRadio>
      {isSelected ? 
        <RadioButtonCheckedIcon sx={{ color: '#7476ff' }} /> : 
        <RadioButtonUncheckedIcon sx={{ color: 'rgba(255,255,255,0.7)' }} />
      }
    </StyledRadio>
  </StyledListItem>
));

const SendTipPopup = ({ userId, budgetInfo, open, onClose, showSnackbar }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tipAmount, setTipAmount] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [tipLoading, setTipLoading] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);

  const handleSearchInput = (e) => {
    const value = e.target.value.slice(0, 32);
    setSearchQuery(value);
    
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (value.trim()) {
      setSearching(true);
      const newTimeout = setTimeout(() => {
        handleSearch(value);
      }, 750);
      setSearchTimeout(newTimeout);
    } else {
      setSearching(false);
      setSearchResults([]);
      setUserSelected(null);
    }
  };

  const handleTipAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d*\.?\d{0,2}$/.test(value) && parseFloat(value) >= 0)) {
      setTipAmount(value);
    }
  };

  const handleSearch = async (query) => {
    try {
      const response = await userApi.searchUsers(query);
      if (response.data.success && Array.isArray(response.data.users)) {
        setSearchResults(response.data.users);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Search error:', error);
      showSnackbar('Search failed, please try again', 'error');
      setSearchResults([]);
    } finally {
      setSearching(false);
    }
  };

  const selectUser = useCallback((user) => {
    setTipLoading(false);
    setTipAmount('');
    setSearchQuery(user ? user.username : '');
    setUserSelected(user);
  }, []);

  const validateTip = () => {
    if (!userSelected) {
      showSnackbar('Please select a user', 'info');
      return false;
    }

    const amount = parseFloat(tipAmount);
    if (!amount || amount <= 0) {
      showSnackbar('Please enter a valid amount', 'info');
      return false;
    }

    if (amount > budgetInfo.budget) {
      showSnackbar('Insufficient balance!', 'error');
      return false;
    }

    if (amount > budgetInfo.initialBudget * 0.2) {
      showSnackbar("You can't tip more than 20% of your budget!", 'error', true);
      return false;
    }

    return true;
  };

  const handleSendTip = async () => {
    if (tipLoading || !validateTip()) {
      return;
    }

    setTipLoading(true);
    try {
      await tipApi.tipUserWebapp({
        userId,
        targetUserId: userSelected.userId,
        amount: parseFloat(tipAmount)
      });
      
      showSnackbar('Tip sent successfully!', 'success');
      selectUser(null);
      setSearchQuery('');
      
    } catch (error) {
      showSnackbar(error?.response?.data?.error || 'Failed to send tip', 'error');
    } finally {
      setTipLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTimeout]);

  const renderUserList = useMemo(() => {
    if (!searchResults.length) {
      return null;
    }
  
    // If we have a selected user, only show that user
    if (userSelected) {
      return (
        <UserListItem
          key={userSelected.userId}
          user={userSelected}
          isSelected={true}
          onSelect={selectUser}
        />
      );
    }
  
    // Otherwise show all search results
    return searchResults.map((user) => (
      <UserListItem
        key={user.userId}
        user={user}
        isSelected={false}
        onSelect={selectUser}
      />
    ));
  }, [searchResults, userSelected, selectUser]);

  return (
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(105%)' }}>
      <IconButton 
        onClick={onClose} 
        sx={{ position: 'absolute', right: 16, top: 16 }}
      >
        <CloseIcon fontSize="large" fontWeight="bold" htmlColor="white" />
      </IconButton>

      <PopupImage 
        src={FoxiCharacter} 
        alt="Foxi" 
        loading='lazy' 
        sx={{ opacity: open ? '1' : '0' }} 
      />
      
      <SearchingImage 
        src={FoxiSearch} 
        alt="Search" 
        loading="lazy" 
        sx={{ 
          opacity: searching ? 1 : 0, 
          transition: 'opacity 0.6s ease-in-out', 
          animation: searching ? `${fadeIn} 1s ease-in-out infinite` : 'none',
          zIndex: 99 
        }} 
      />

      <Box display="flex" flexDirection="column" alignItems="center" p={2} mt={8}>
        <StyledTextField
          value={searchQuery}
          onChange={handleSearchInput}
          variant="outlined"
          placeholder="Search by Name or Username"
          type='text'
          fullWidth
          inputProps={{ maxLength: 32 }}
          sx={{ mb: 2 }}
        />
        
        {userSelected && (
          <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
            <StyledTextField
              value={tipAmount}
              onChange={handleTipAmountChange}
              variant="outlined"
              placeholder="Tip Amount"
              type="number"
              inputProps={{ 
                min: 0,
                step: "5"
              }}
              sx={{ width: '48%' }}
            />
            <Grid 
              container 
              direction="column" 
              justifyContent="center" 
              alignItems="center" 
              width="48%" 
              border='3px solid white' 
              borderRadius='30px'
            >
              <Typography 
                variant="h6" 
                fontSize="15px" 
                fontFamily="sora, sans-serif" 
                fontWeight="500" 
                sx={{ color: 'gold', position: 'relative', top: '5px' }}
              >
                Budget Left
              </Typography>
              <Typography 
                variant="h6" 
                fontSize="17px" 
                fontFamily="sora, sans-serif" 
                fontWeight="bold" 
                sx={{ color: 'gold' }}
              >
                {formatNumber(budgetInfo?.budget || 0)}
              </Typography>
            </Grid>
          </Box>
        )}

        <StyledList>
          {renderUserList}
        </StyledList>

        {userSelected && (
          <>
            <StyledButton
              isCancellButton={true}
              variant="contained"
              color="error"
              onClick={() => selectUser(null)}
              sx={{ bottom: '2vh' }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleSendTip}
              sx={{ 
                bottom: '11vh',
                opacity: tipLoading ? 0.8 : 1,
              }}
            >
              {tipLoading ? (
                <span>
                  Sending... 
                  <CircularProgress 
                    size={20} 
                    thickness={5} 
                    disableShrink 
                    sx={{ color: 'white', ml: 1, verticalAlign: 'middle' }} 
                  />
                </span>
              ) : (
                <span>
                  Send Tip 
                  <SendIcon sx={{ fontSize: '22px', ml: 1, verticalAlign: 'middle' }} />
                </span>
              )}
            </StyledButton>
          </>
        )}
      </Box>
    </PopupContainer>
  );
};

SendTipPopup.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  budgetInfo: PropTypes.shape({
    budget: PropTypes.number.isRequired,
    initialBudget: PropTypes.number.isRequired
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

export default React.memo(SendTipPopup);