import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, LinearProgress, Link } from '@mui/material';
import { styled } from '@mui/system';

// Import backgrounds
import FoxiLoadingBG from '../../assets/images/loading-bg.png';
import Background from '../../assets/images/bg.png';
import FoxiCharacter from '../../assets/images/home-elements/foxi-character.png';

// Import social icons
import Telegram from '../../assets/images/icons/telegram.png';
import X from '../../assets/images/icons/x.png';
import Youtube from '../../assets/images/icons/youtube.png';

// Social media links configuration
const SOCIALS = [
  { 
    id: 'telegram', 
    url: 'https://t.me/Foxi_Tips', 
    icon: Telegram,
    width: '9.5vw' 
  },
  { 
    id: 'x', 
    url: 'https://x.com/foxi_tips', 
    icon: X,
    width: '10vw', 
    margin: true 
  },
  { 
    id: 'youtube', 
    url: 'https://youtube.com/@foxi_tips', 
    icon: Youtube,
    width: '10vw' 
  }
];

// Styled Components
const StyledGrid = styled(Grid)({
  width: '105vw',
  height: '105vh',
  position: 'absolute',
  zIndex: 999,
  backgroundColor: 'black',
  backdropFilter: 'blur(10px) drop-shadow(2px 4px 6px black)',
  backgroundSize: 'cover'
});

const ContentWrapper = styled(Box)({
  position: 'absolute',
  bottom: '5vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

const GlowBox = styled(Box)({
  position: 'absolute',
  bottom: '28vh',
  left: '5vw',
  width: '0px',
  height: '10vh',
  borderRadius: '10px',
  bgcolor: 'white',
  boxShadow: '-1px 0px 66px 76px #000000db, 2px -20px 20px 6px #000000'
});

const SocialLinks = memo(() => (
  <Grid 
    container 
    sx={{ 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'center', 
      height: '35px', 
      my: 1.5, 
      borderRadius: '20px' 
    }}
  >
    {SOCIALS.map(({ id, url, icon, width, margin }) => (
      <Link 
        key={id}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Box
          component="img"
          loading="lazy"
          src={icon}
          alt={id}
          sx={{ 
            width,
            ...(margin && { mx: 0.5 }),
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.1)'
            }
          }}
        />
      </Link>
    ))}
  </Grid>
));

SocialLinks.displayName = 'SocialLinks';

const LoadingScreen = memo(({ whitelistGradient = false }) => {
  const backgroundImage = whitelistGradient 
    ? 'linear-gradient(356deg, #0e002f, #0076e0a3)'
    : `url(${FoxiLoadingBG})`;

  return (
    <StyledGrid
      item 
      xs={12} 
      sx={{ background: backgroundImage, backgroundSize: 'cover' }}
    >
      <ContentWrapper>
        <GlowBox />
        
        <Typography 
          variant="body2" 
          sx={{
            fontSize: '4.25vw',
            fontFamily: 'Poppins-Bold, sans-serif',
            fontWeight: 'bold',
            color: 'white',
            textAlign: 'center',
            userSelect: 'none'
          }}
        >
          Foxi give you daily budget <br/>
          You just spend it by the end of the day
        </Typography>

        <LinearProgress 
          color="secondary" 
          sx={{ 
            mt: 1.5, 
            width: '90vw', 
            height: '2vh', 
            borderRadius: '20px' 
          }} 
        />

        <SocialLinks />
      </ContentWrapper>

      {/* Preload critical assets */}
      <link rel="preload" href={FoxiLoadingBG} as="image" />
      <link rel="preload" href={Background} as="image" />
      <link rel="preload" href={FoxiCharacter} as="image" />
    </StyledGrid>
  );
});

LoadingScreen.propTypes = {
  whitelistGradient: PropTypes.bool
};

LoadingScreen.displayName = 'LoadingScreen';

export default LoadingScreen;