// src/App.js
import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import socketClient from './socket';

// Components
import Snowfall from 'react-snowfall'
import WonkaHome from './components/Home';
import TaskPage from './components/Task';
import RewardsPage from './components/Rewards';
import ReferralPage from './components/Referral';
import MaintenancePage from './components/Maintenance';
import LoadingScreen from './components/Home/LoadingScreen';
import DesktopPage from './components/DesktopPage';
import SocketMonitor from './components/Admin/SocketMonitor';
import CustomSnackbar from './components/Snakebar/Snakebar';


// Utils
import useImagePreloader from './utils/ImagePreloader';
import { userApi } from './services/api';

const telApp = window.Telegram.WebApp;

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
});

const ENDPOINT = process.env.REACT_APP_API_URL;

function App() {
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const [userPlatform, setUserPlatform] = useState(null);
  const [openConfetti, setOpenConfetti] = useState(false);
  // Snow Version
  const [openSantaPopup, setOpenSantaPopup] = useState(true);
  const { imagesLoaded, progress } = useImagePreloader();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
    doubleDuration: false
  });

  // Initialize Telegram WebApp and Socket
  const init = async () => {
    telApp.ready();    
    telApp.expand();

    setUserPlatform(telApp.platform);
    const startAppId = Number(telApp.initDataUnsafe.start_param);
    const initData = telApp.initData;
    
    if (initData) {
        try {
            const response = await userApi.authenticateUser(initData, startAppId);
            if (response.data.authenticated) {
                localStorage.setItem('jwtToken', response.data.token);
                setUserData(response.data.user);
                setIsTelegramMiniApp(true);
                
                socketClient.initialize(ENDPOINT, response.data.token);
                await getProfileInfo(response.data.user.id);
            } else {
                throw new Error('Authentication failed');
            }
        } catch (error) {
            console.error('Authentication error:', error);
            telApp.showAlert('Authentication failed. Please try again.');
            telApp.close();
        }
    } else {
        // Development mode
        setIsTelegramMiniApp(false);

        if (process.env.REACT_APP_NODE_ENV === 'local') {
          const devUser = {
              "id": 1887509957,
              "first_name": "Its Justin",
              "last_name": "",
              "username": "P2P_JS",
              "language_code": "en",
              "is_premium": true,
              "allows_write_to_pm": true
          };
          setUserData(devUser);
 
          // Create a development token
          const devToken = 'dev_' + Date.now();
          localStorage.setItem('jwtToken', devToken);
          
          // Initialize socket with dev token
          socketClient.initialize(ENDPOINT, devToken);
          await getProfileInfo(devUser.id);
       } else {
           // Prodoction mode
           window.location.href = 'https://t.me/FoxiTips_bot';
       }
    }
};

  const getProfileInfo = async (userId) => {
    try {
      const response = await userApi.getUserData(userId);
      telApp.setHeaderColor('#010510');
      setTimeout(() => {
        setUserProfileInfo(response.data);
      }, 1000);
      
      setProfileUrl(`https://app.foxi.tips/api/public/users/avatars/${userId}.jpg`);
    } catch (error) {
      console.error('Error fetching user data:', error);
      telApp?.showAlert('Failed to get user information data!\nFor support, Please check bot profile bio!');
      telApp.close();
    }
  };

  // Setup socket subscription for user updates
  useEffect(() => {
    if (userData?.id) {
      // Subscribe to user updates
      const cleanup = socketClient.subscribeToUserUpdates((updatedUserData) => {
        setUserProfileInfo(prevState => ({
          ...prevState,
          ...updatedUserData
        }));
      });

      // Cleanup on unmount
      return () => {
        cleanup();
        socketClient.disconnect();
      };
    }
  }, [userData?.id]);

  // Initial setup
  useEffect(() => {
    init();
  }, []);

  const showSnackbar = (message, type, doubleDuration = false) => {
    setSnackbar({ open: true, message, type, doubleDuration });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // Handle desktop platform
  if (userPlatform === 'tdesktop' && userData?.id !== 1887509957) {
    telApp.setHeaderColor('#1D0A37');
    return <DesktopPage />;
  }

  // Show loading screen
  if (!userProfileInfo || !imagesLoaded) {
    telApp.setHeaderColor('#000');
    return <LoadingScreen progressPercentage={progress} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Snowfall snowflakeCount={100} />

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        duration={snackbar.doubleDuration ? 4000 : 2000}
        onClose={closeSnackbar}
      />

      <Routes>
        <Route 
          path="/" 
          element={
            <WonkaHome 
              theme={theme}
              userData={userData}
              userProfileInfo={userProfileInfo} 
              setUserProfileInfo={setUserProfileInfo}
              profileUrl={profileUrl}
              userPlatform={userPlatform} 
              openConfetti={openConfetti}
              setOpenConfetti={setOpenConfetti}
              showSnackbar={showSnackbar}
              telApp={telApp}
              ENDPOINT={ENDPOINT}

              // Snow Version
              openSantaPopup={openSantaPopup}
              setOpenSantaPopup={setOpenSantaPopup}
            />
          } 
        />
        
        <Route 
          path="/task" 
          element={
            <TaskPage 
              theme={theme}
              userProfileInfo={userProfileInfo} 
              openConfetti={openConfetti}
              setOpenConfetti={setOpenConfetti}
              showSnackbar={showSnackbar}
              telApp={telApp}
              ENDPOINT={ENDPOINT}
            />
          } 
        />

        <Route 
          path="/rewards" 
          element={
            <RewardsPage
              theme={theme}
              userProfileInfo={userProfileInfo} 
              setUserProfileInfo={setUserProfileInfo}
              openConfetti={openConfetti}
              setOpenConfetti={setOpenConfetti}
              showSnackbar={showSnackbar}
              telApp={telApp}
              ENDPOINT={ENDPOINT}
            />
          } 
        />

        <Route 
          path="/referrals" 
          element={
            <ReferralPage
              theme={theme}
              userData={userData}
              userProfileInfo={userProfileInfo}
              telApp={telApp}
              ENDPOINT={ENDPOINT}
            />
          } 
        />

        <Route path="/maintenance" element={<MaintenancePage />} />
   
        <Route path="/admin/socket-monitor" element={<SocketMonitor />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;