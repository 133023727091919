// src/socket/index.js
import { io } from 'socket.io-client';

class SocketClient {
    constructor() {
        this.socket = null;
        this.userId = null;
        this.connected = false;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.reconnectDelay = 1000;
        this.pingTimeout = null;
        this.reconnectTimer = null;
        this.forcedDisconnect = false;
        this.endpoint = null;
        this.token = null;
        this.debug = process.env.NODE_ENV === 'development';
    }

    initialize(endpoint, token) {
        console.log('Initializing socket with token:', token);
        this.endpoint = endpoint;
        this.token = token;
        this.userId = process.env.NODE_ENV === 'development' ? '1887509957' : null;

        if (this.socket?.connected) {
            console.log('Socket already connected');
            return this;
        }

        const cleanEndpoint = endpoint.replace(/\/api$/, '');
        console.log('Cleaned endpoint:', cleanEndpoint);

        this._connect(cleanEndpoint);
        return this;
    }

    _connect(endpoint) {
        try {
            // Clear any existing socket
            if (this.socket) {
                this.socket.removeAllListeners();
                this.socket.close();
            }

            this.socket = io(endpoint, {
                auth: { token: this.token },
                reconnection: true,
                reconnectionAttempts: this.maxReconnectAttempts,
                reconnectionDelay: this.reconnectDelay,
                reconnectionDelayMax: 5000,
                timeout: 10000,
                transports: ['websocket']
            });

            this._setupEventHandlers();

        } catch (error) {
            console.error('Socket connection error:', error);
            this._handleReconnect();
        }
    }

    _setupEventHandlers() {
        this.socket.on('connect', () => {
            console.log('Socket connected successfully with ID:', this.socket.id);
            this.connected = true;
            this.reconnectAttempts = 0;
            this.forcedDisconnect = false;
            this._resetPingTimeout();
        });

        this.socket.on('disconnect', (reason) => {
            console.log('Socket disconnected:', reason);
            this.connected = false;
            this._clearPingTimeout();

            if (!this.forcedDisconnect) {
                // Only attempt reconnect if not forced
                this._handleReconnect();
            }
        });

        this.socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
            this._handleReconnect();
        });

        this.socket.on('ping', () => {
            this._resetPingTimeout();
            this.socket.emit('pong');
        });
    }

    _handleReconnect() {
        if (this.reconnectTimer) {
            clearTimeout(this.reconnectTimer);
        }

        if (this.forcedDisconnect) {
            console.log('Skipping reconnect due to forced disconnect');
            return;
        }

        if (this.reconnectAttempts >= this.maxReconnectAttempts) {
            console.log('Max reconnection attempts reached');
            this.reconnectAttempts = 0;
            setTimeout(() => {
                this._connect(this.endpoint);
            }, 30000);
            return;
        }

        this.reconnectAttempts++;
        const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 10000);
        
        console.log(`Attempting reconnect ${this.reconnectAttempts}/${this.maxReconnectAttempts} in ${delay}ms`);
        
        this.reconnectTimer = setTimeout(() => {
            if (!this.socket?.connected && !this.forcedDisconnect) {
                this._connect(this.endpoint);
            }
        }, delay);
    }

    _resetPingTimeout() {
        this._clearPingTimeout();
        this.pingTimeout = setTimeout(() => {
            console.log('Ping timeout - reconnecting');
            if (this.socket?.connected) {
                this.socket.disconnect();
                this._handleReconnect();
            }
        }, 30000); // Reduced from 35000 to match server
    }

    _clearPingTimeout() {
        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
            this.pingTimeout = null;
        }
    }

    subscribeToUserUpdates(callback) {
        if (!this.socket) {
            console.error('Socket not initialized');
            return () => {};
        }
    
        console.log('Setting up user update subscription for userId:', this.userId);
    
        this.socket.on('user:update', (userData) => {
            console.log('Received user update:', userData);
            callback(userData);
        });
    
        return () => {
            console.log('Cleaning up user update subscription');
            this.socket?.off('user:update');
        };
    }

    isConnected() {
        return this.socket?.connected || false;
    }

    disconnect() {
        this.forcedDisconnect = true;
        this._clearPingTimeout();
        if (this.reconnectTimer) {
            clearTimeout(this.reconnectTimer);
            this.reconnectTimer = null;
        }
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
        this.connected = false;
        this.reconnectAttempts = 0;
    }
}

const socketClient = new SocketClient();
export default socketClient;