import React, { useState, useEffect, useCallback, memo } from 'react';
import { Box, Button, Typography, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { taskApi } from '../../services/api';
import AlertBadge from '../AlertBadge/AlertBadge';
import {
  TASK_ICONS,
  REWARD_ICONS
} from '../Task';

// Task Categories Constants
const TASK_CATEGORIES = {
  TELEGRAM: 'Telegram',
  X: 'X',
  YOUTUBE: 'Youtube',
  FARCASTER: 'Farcaster'
};

// Task countdown times (in seconds)
const TASK_TIMES = {
  [TASK_CATEGORIES.X]: 10,
  [TASK_CATEGORIES.YOUTUBE]: task => task.title.toLowerCase().includes('watch') ? null : 10,
  [TASK_CATEGORIES.TELEGRAM]: 10,
  [TASK_CATEGORIES.FARCASTER]: 10
};

// Verification States
const VERIFICATION_STATES = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed'
};


const PopupContainer = styled(Box)(({ theme, customHeight = null }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100vw',
  height: customHeight || '55vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  borderTopLeftRadius: '35px',
  borderTopRightRadius: '35px',
  borderTop: '5px solid orange',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
  '*': {
    color: 'white',
  },
  zIndex: 999,
}));

const StyledTaskButton = styled(Button, {
  shouldForwardProp: prop => !['isActive', 'isWaiting'].includes(prop)
})(({ theme, isActive, isWaiting }) => ({
  borderRadius: '10rem',
  color: 'white !important',
  textTransform: 'none',
  height: '7.5vh',
  width: '80%',
  padding: '12px 16px',
  backgroundColor: isActive ? 'rgb(4 75 255)' : 
                 isWaiting ? 'rgb(4 75 255)' : '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent), radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: 'inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3ax 1px #5529ec',
  textShadow: isActive ? '0 1px 1px rgba(255, 165, 0, 0.7)' : '0 1px 1px #3829ec',
  transition: 'all 0.2s ease-in-out',
  cursor: isWaiting ? 'not-allowed' : 'pointer',
  fontSize: '17px',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  opacity: isWaiting ? 0.7 : 1,
  filter: 'hue-rotate(300deg)',
  '&:hover': {
    transform: isWaiting ? 'none' : 'scale(1.02)',
    backgroundColor: 'rgb(232 4 255)',
  },
  '&:active': {
    transform: isWaiting ? 'none' : 'scale(0.98)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:disabled': {
    backgroundColor: '#666',
    cursor: 'not-allowed',
  }
}));

const StyledTextField = styled(TextField)({
  width: '80%',
  '& .MuiOutlinedInput-root': {
    color: 'white',
    borderRadius: '20px',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#5529ec',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
});

const ConfirmTaskPopup = memo(({ 
  task, 
  userId, 
  open, 
  onClose, 
  setOpenConfetti,
  showSnackbar
}) => {
  const [verificationState, setVerificationState] = useState(VERIFICATION_STATES.NOT_STARTED);
  const [countdown, setCountdown] = useState(null);
  const [verificationKey, setVerificationKey] = useState('');
  const [taskStarted, setTaskStarted] = useState(false);
  const [canVerify, setCanVerify] = useState(false);

  const isYoutubeWatchTask = task?.category === TASK_CATEGORIES.YOUTUBE && 
                            task?.title.toLowerCase().includes('watch');

  useEffect(() => {
    if (!open) {
      setVerificationState(VERIFICATION_STATES.NOT_STARTED);
      setCountdown(null);
      setVerificationKey('');
      setTaskStarted(false);
      setCanVerify(false);
    }
  }, [open]);

  useEffect(() => {
    let timer;
    if (taskStarted && countdown !== null && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            if (!isYoutubeWatchTask) {
              setCanVerify(true);
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [taskStarted, countdown, isYoutubeWatchTask]);

  const handleStartTask = useCallback(() => {
    if (!task?.link) {
      showSnackbar('Invalid task link', 'error');
      return;
    }

    const countdownTime = typeof TASK_TIMES[task.category] === 'function' 
      ? TASK_TIMES[task.category](task)
      : TASK_TIMES[task.category];

    setTaskStarted(true);
    setCountdown(countdownTime);
    window.open(task.link, '_blank');
  }, [task, showSnackbar]);

  const handleVerificationKeyChange = (event) => {
    const value = event.target.value;
    setVerificationKey(value);
    if (isYoutubeWatchTask) {
      setCanVerify(value.length > 0);
    }
  };

  const handleTaskVerification = useCallback(async () => {
    if (!task?.taskId || !userId) {
      showSnackbar('Loading task data, please try again.', 'info');
      return;
    }
  
    if (isYoutubeWatchTask) {
      if (!verificationKey) {
        showSnackbar('Please enter verification key', 'error');
        return;
      }
      
      // Check if verification key matches before making API call
      if (verificationKey !== task.verificationKey) {
        showSnackbar('Invalid verification key. Please check comments and find the correct key.', 'error',  );
        return;
      }
    }
      
    try {
      const response = await taskApi.checkTask(userId, task.taskId);
        
      if (!response?.data?.success) {
        throw new Error(response?.data?.error || 'Verification failed');
      }
    
      setOpenConfetti(true);
      onClose();
      showSnackbar('Task completed successfully!', 'success');
    } catch (error) {
      console.error('Error verifying task:', error);
      showSnackbar(error?.response?.data?.error || 'Failed to verify task', 'error');
      if (error.message !== 'Error fetching task or user data') {
        onClose();
      }
    }
  }, [task, userId, verificationKey, isYoutubeWatchTask, onClose, setOpenConfetti, showSnackbar]);

  const renderVerifyButton = () => {
    let buttonText = 'Complete Task First';
    let isButtonEnabled = false;

    if (taskStarted) {
      if (isYoutubeWatchTask) {
        buttonText = verificationKey.length > 0 ? 'Verify Task' : 'Enter Verification Key';
        isButtonEnabled = verificationKey.length > 0;
      } else {
        if (countdown > 0) {
          buttonText = `Wait ${countdown}s`;
          isButtonEnabled = false;
        } else {
          buttonText = 'Verify Task';
          isButtonEnabled = true;
        }
      }
    }

    return (
      <StyledTaskButton 
        onClick={handleTaskVerification}
        disabled={!isButtonEnabled}
        isActive={isButtonEnabled}
      >
        {buttonText}
        {isButtonEnabled && (
          <AlertBadge pulseSpeed={1} top={-4} right={0} size={17} />
        )}
      </StyledTaskButton>
    );
  };

  return (
    <PopupContainer 
      customHeight={isYoutubeWatchTask && '62vh'} 
      sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)' }}
    >
      <Box display="flex" justifyContent="space-between" mt={2} mr={2}>
        <Typography variant="h5" fontFamily="Poppins-Medium, sans-serif" fontSize="20px" ml={2}>
          {task?.category} Task
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>

      <Box display="flex" gap="5px" flexDirection="column" justifyContent="center" alignItems="center" position="relative" bottom="20px">
        <img src={TASK_ICONS[task?.category]} alt={task?.type} width="110px" height="110px" />
        <Box display="flex" flexDirection="column" gap="5px" position="relative" bottom="8px">
          <Typography variant="h6" fontFamily="Poppins-Medium, sans-serif" fontSize={taskStarted ? '14px' : '20px'} ml={2}>
            {taskStarted && isYoutubeWatchTask 
              ? "Check video comments and find verification key" 
              : task?.title.length > 35 ? `${task?.title.slice(0, 35)}...` : task?.title}
          </Typography>
          {(!taskStarted || !isYoutubeWatchTask) && (
            <Box display="flex" gap="5px" justifyContent="center" alignItems="center" mt={0.25}>
              {task?.diamonds > 0 && (
                <>
                  <img src={REWARD_ICONS.DIAMOND} alt="Diamond" style={{ width: '18px', marginRight: '2px' }} />
                  {task.diamonds}&nbsp;&nbsp;
                </>
              )}
              <img src={REWARD_ICONS.COIN} alt="Points" style={{ width: task?.diamonds > 0 ? '16px' : '17px', marginRight: '2px' }} />
              {task?.points}
            </Box>
          )}
        </Box>

        {isYoutubeWatchTask && (
          <StyledTextField
            label="Verification Key"
            value={verificationKey}
            onChange={handleVerificationKeyChange}
            variant="outlined"
            disabled={!taskStarted}
          />
        )}
      </Box>

      <Box 
        display="flex" 
        gap="8px"
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        width="100%" 
        height="30%" 
        position="relative" 
        bottom={isYoutubeWatchTask ? "2vh" : "0vh"}
      >
        <StyledTaskButton 
          onClick={handleStartTask}
          disabled={taskStarted}
        >
          {taskStarted ? 'Task Started' : 'Start Task'}
        </StyledTaskButton>
        {renderVerifyButton()}
      </Box>
    </PopupContainer>
  );
});

ConfirmTaskPopup.propTypes = {
  task: PropTypes.shape({
    taskId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    diamonds: PropTypes.number,
    verificationKey: PropTypes.string
  }),
  userId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setOpenConfetti: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
};

ConfirmTaskPopup.displayName = 'ConfirmTaskPopup';

export default ConfirmTaskPopup;