import React, { useState, useEffect, useCallback } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { styled, keyframes } from '@mui/system';
import { Box, Typography, ToggleButtonGroup, ToggleButton, Container } from '@mui/material';
import { taskApi } from '../services/api';
import BottomTabs from './Tabs/Tabs';
import TaskList from './Task/TaskList';
import ConfirmPopup from './Task/ConfirmPopup';
import Confetti from './Confetti/Confetti';
import GradientBoxShadow from './GradientBoxShadow/GradientBoxShadow';

// Import icons
import XIcon from '../assets/images/icons/x.png';
import YoutubeIcon from '../assets/images/icons/youtube.png';
import FarcasterIcon from '../assets/images/icons/farcaster.png';
import TelegramIcon from '../assets/images/icons/telegram.png';

// Task Categories and Types
export const TASK_CATEGORIES = {
  TELEGRAM: 'Telegram',
  X: 'X',
  YOUTUBE: 'Youtube',
  FARCASTER: 'Farcaster'
};

export const TASK_TYPES = {
  BASIC: 'Basic',
  DAILY: 'Daily'
};

// Task Time Requirements (in seconds)
export const TASK_REQUIREMENTS = {
  // YouTube actions
  'YOUTUBE_WATCH': {
    minTime: 30,
    errorMessage: 'You must watch the video for at least 30 seconds!'
  },
  'YOUTUBE_SUBSCRIBE': {
    minTime: 8,
    errorMessage: 'You must stay on YouTube to subscribe!'
  },
  // X (Twitter) actions
  'X_FOLLOW': {
    minTime: 8,
    errorMessage: 'You must stay on X to follow!'
  },
  'X_REPOST': {
    minTime: 12,
    errorMessage: 'You must stay on X to repost!'
  },
  // Farcaster actions
  'FARCASTER_FOLLOW': {
    minTime: 13,
    errorMessage: 'You must stay on Farcaster to follow!'
  }
};

// Icons mapping
export const TASK_ICONS = {
  [TASK_CATEGORIES.TELEGRAM]: TelegramIcon,
  [TASK_CATEGORIES.X]: XIcon,
  [TASK_CATEGORIES.YOUTUBE]: YoutubeIcon,
  [TASK_CATEGORIES.FARCASTER]: FarcasterIcon
};

export const REWARD_ICONS = {
  COIN: 'https://img.icons8.com/fluency/50/cheap-2.png',
  DIAMOND: 'https://img.icons8.com/color/48/diamond.png'
};

const STATIC_STARS = [
  { top: '9.5vh', right: '22vw', size: '40px', delay: '2s' },
  { top: '3.5vh', right: '12vw', size: '55px', delay: '3.5s' },
  { top: '9.5vh', right: '4vw', size: '60px', delay: '4.5s' }
];

// Theme configuration
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#9c27b0' },
    secondary: { main: '#f48fb1' },
    background: {
      default: '#1a0933',
      paper: '#2a1052',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
  },
});

// Animations
const twinkle = keyframes`
  0% { opacity: 1; transform: scale(1); filter: drop-shadow(0 0 10px gold); }
  50% { opacity: 0.5; transform: scale(0.9); filter: drop-shadow(0 0 15px gold); }
  100% { opacity: 1; transform: scale(1); filter: drop-shadow(0 0 10px gold); }
`;

// Styled Components
const StyledTaskToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  justifyContent: 'center',
  '& .MuiToggleButtonGroup-grouped': {
    border: '2px solid rgba(255, 255, 255, 0.5)',
    width: '35vw',
    fontFamily: 'Poppins-Medium, sans-serif',
    fontWeight: 'bold',
    color: 'white',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&.Mui-selected': {
      backgroundColor: '#5529ec',
      color: 'white',
      boxShadow: `
        inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6),
        inset 0 -4px 4px 1px #c5b3ff,
        inset 0 0 0px 8px rgba(255, 255, 255, 0.2),
        0 1px 4px 1px rgba(56, 41, 236, 0.2),
        0 1px 3ax 1px #5529ec
      `,
      filter: 'hue-rotate(300deg)',
      '&:hover': {
        backgroundColor: '#6339ec',
      }
    }
  }
}));

const StyledTaskToggleButton = styled(ToggleButton)(({ active, side }) => ({
  textTransform: 'none',
  height: '45px',
  fontSize: '15px',
  position: 'relative',
  borderRadius: side === "left" ? '10px 0 0 10px' : '0 10px 10px 0',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: active ? 
      'linear-gradient(to bottom, #e9f0ff, transparent)' : 
      'none',
    opacity: 0.75,
  }
}));

// Utility Functions
export const getTaskRequirements = (category, title = '') => {
  if (!category) return null;
  
  const lowerTitle = title.toLowerCase();
  
  // YouTube tasks
  if (category === TASK_CATEGORIES.YOUTUBE) {
    if (lowerTitle.includes('watch')) {
      return TASK_REQUIREMENTS.YOUTUBE_WATCH;
    }
    if (lowerTitle.includes('subscribe')) {
      return TASK_REQUIREMENTS.YOUTUBE_SUBSCRIBE;
    }
  }
  
  // X (Twitter) tasks
  if (category === TASK_CATEGORIES.X) {
    if (lowerTitle.includes('repost')) {
      return TASK_REQUIREMENTS.X_REPOST;
    }
    return TASK_REQUIREMENTS.X_FOLLOW;
  }
  
  // Farcaster tasks
  if (category === TASK_CATEGORIES.FARCASTER) {
    return TASK_REQUIREMENTS.FARCASTER_FOLLOW;
  }
  
  return null;
};

const Task = ({ userProfileInfo, showSnackbar, telApp, ENDPOINT }) => {
  const [taskType, setTaskType] = useState(TASK_TYPES.BASIC);
  const [tasks, setTasks] = useState([]);
  const [activeTask, setActiveTask] = useState(null);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openConfetti, setOpenConfetti] = useState(false);
  const [stopFetchingTasks, setStopFetchingTasks] = useState(false);

  const fetchTasks = useCallback(async () => {
    if (stopFetchingTasks) return;

    try {
      const response = await taskApi.getAllTasks();
      if (!response?.data?.tasks) {
        console.error('Invalid task data received');
        return;
      }

      const mappedTasks = response.data.tasks
        .map(task => ({
          ...task,
          type: task.type?.toLowerCase() || 'unknown'
        }))
        .sort((a, b) => {
          const typeOrder = { telegram: 1, x: 2, youtube: 3, farcaster: 4 };
          return (typeOrder[a.type] || 5) - (typeOrder[b.type] || 5);
        });

      setTasks(mappedTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      showSnackbar('Failed to fetch tasks. Please try again later.', 'error');
    }
  }, [showSnackbar]);

  useEffect(() => {
    fetchTasks();
    telApp?.setHeaderColor('#010510');
  }, [fetchTasks, showSnackbar, telApp]);

  const handleTaskSelection = useCallback((taskId) => {
    const selectedTask = tasks.find(t => t.taskId === taskId);
    
    if (!selectedTask) {
      showSnackbar('Task not found. Please try again.', 'error');
      return;
    }

    if (!userProfileInfo?.taskProgress?.completed) {
      showSnackbar('Please log in to perform tasks.', 'error');
      return;
    }

    if (userProfileInfo.taskProgress.completed.includes(taskId)) {
      showSnackbar('You have already completed this task!', 'error');
      return;
    }

    setActiveTask(selectedTask);
    setOpenConfirmPopup(true);
  }, [tasks, userProfileInfo, showSnackbar]);

  const handleTaskTypeChange = (_, newType) => {
    if (newType !== null) {
      setTaskType(newType);
    }
  };

  const getUncompletedTaskCount = useCallback(() => {
    if (!tasks || !userProfileInfo?.taskProgress?.completed) return 0;
    
    return tasks.filter(task => 
      task.type?.toLowerCase() === taskType.toLowerCase() &&
      !userProfileInfo.taskProgress.completed.includes(task.taskId)
    ).length;
  }, [tasks, userProfileInfo, taskType]);

  const renderStars = () => (
    <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
      pointerEvents: 'none',
    }}>
      {[...Array(5)].map((_, i) => (
        <Box
          key={`random-star-${i}`}
          component="img"
          src="https://img.icons8.com/pulsar-gradient/50/star.png"
          alt="Star"
          sx={{
            position: 'absolute',
            width: '40px',
            height: '40px',
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            rotate: `${Math.random() * 360}deg`,
            opacity: 0.15,
            transition: 'all 1s ease-in-out',
            filter: 'drop-shadow(0 0 13px gold) drop-shadow(0 0 5px gold)',
          }}
        />
      ))}

      {STATIC_STARS.map((star, index) => (
        <Box
          key={`static-star-${index}`}
          component="img"
          src="https://img.icons8.com/pulsar-gradient/50/star.png"
          alt="Star"
          sx={{
            position: 'absolute',
            width: star.size,
            height: star.size,
            top: star.top,
            right: star.right,
            filter: 'drop-shadow(0 0 13px gold)',
            animation: `${twinkle} ${star.delay} infinite`,
          }}
        />
      ))}
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{ 
        minHeight: '100vh',
        bgcolor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        pr: 0,
        pl: '10px',
        pt: '2vh',
        overflow: 'hidden'
      }}>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', py: 4, zIndex: 1 }}>
          <Typography 
            variant="h4" 
            component="h1" 
            fontFamily="Poppins-Bold, sans-serif" 
            fontWeight="bold" 
            sx={{ color: 'white', mb: 1, textAlign: 'center' }}
          >
            E A R N
          </Typography>
          <Typography 
            variant="body1" 
            fontFamily="sora, sans-serif" 
            fontWeight="bold" 
            sx={{ color: '#f48fb1', mb: 3, textAlign: 'center' }}
          >
            Complete Tasks and earn bonuses
          </Typography>

          {renderStars()}

          <Typography 
            variant="body2" 
            color="white" 
            textAlign="center" 
            fontFamily="Poppins-Medium, sans-serif" 
            mb={1}
          >
            {getUncompletedTaskCount()} tasks remaining
          </Typography>

          <StyledTaskToggleButtonGroup
            value={taskType}
            exclusive
            onChange={handleTaskTypeChange}
            aria-label="task type"
          >
            <StyledTaskToggleButton
              value={TASK_TYPES.BASIC}
              aria-label="basic tasks"
              active={taskType === TASK_TYPES.BASIC}
              side="left"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img 
                  src="https://img.icons8.com/fluency/100/four-squares.png"
                  alt="basic"
                  style={{ 
                    width: '20px', 
                    height: '20px',
                    filter: taskType === TASK_TYPES.BASIC ? 'brightness(1.2)' : 'brightness(0.9)'
                  }} 
                />
                Basic
              </Box>
            </StyledTaskToggleButton>
            <StyledTaskToggleButton
              value={TASK_TYPES.DAILY}
              aria-label="daily tasks"
              active={taskType === TASK_TYPES.DAILY}
              side="right"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <img 
                  src="https://img.icons8.com/3d-fluency/94/time.png"
                  alt="daily"
                  style={{ 
                    width: '20px', 
                    height: '20px',
                    filter: taskType === TASK_TYPES.DAILY ? 'brightness(1.2)' : 'brightness(0.9)'
                  }} 
                />
                Daily
              </Box>
            </StyledTaskToggleButton>
          </StyledTaskToggleButtonGroup>

          <Box sx={{ flexGrow: 1, overflowY: 'auto', zIndex: 1 }}>
            <TaskList 
              tasks={tasks}
              taskType={taskType.toLowerCase()}
              completedTasks={userProfileInfo?.taskProgress?.completed || []}
              showSnackbar={showSnackbar}
              onTaskSelect={handleTaskSelection}
            />
          </Box>

          <GradientBoxShadow />
        </Box>

        <ConfirmPopup 
          task={activeTask}
          userId={userProfileInfo?.userId}
          open={openConfirmPopup}
          onClose={() => {
            setOpenConfirmPopup(false);
            setActiveTask(null);
          }}
          setOpenConfetti={setOpenConfetti}
          showSnackbar={showSnackbar}
          ENDPOINT={ENDPOINT}
        />

       <Confetti 
          openConfetti={openConfetti} 
          setOpenConfetti={setOpenConfetti} 
          x={0.5} 
          y={0.85} 
        />

        <Box sx={{ position: 'sticky', bottom: 0, left: 0, right: 0, zIndex: 10 }}>
          <BottomTabs activeTab={1} blurEffect={true} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

Task.propTypes = {
  userProfileInfo: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    taskProgress: PropTypes.shape({
      completed: PropTypes.arrayOf(PropTypes.string),
      lastCompletedAt: PropTypes.string,
      stats: PropTypes.shape({
        pointsEarned: PropTypes.number,
        diamondsEarned: PropTypes.number
      }),
      totalCompleted: PropTypes.number
    })
  }).isRequired,
  showSnackbar: PropTypes.func.isRequired,
  telApp: PropTypes.object.isRequired,
  ENDPOINT: PropTypes.string.isRequired
};

export default Task;